<!--
File: Districts.vue
Description: show list of DEU's entered in the DB.
-->
<template>
  <base-data-table v-if="eligible" :loading="showSpinner" :pagination="pagination"
    :btnAddAllowed="isBtnAllowed('AddButton')" :from="from" :to="to" :total="total" @add-item="viewItem()">

    <template #header-filters>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <RegionsDropdown v-model="selectedRegion" />
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <search-input v-model="searchQuery" />
      </div>
    </template>

    <template #table-rows="{ item }">
      <base-table :items="queriedData" :headers="headers" :loading="showSpinner" :idFieldName="idFieldName"
        :sort="currentSort" :sortOrder="currentSortOrder">

        <template #table-actions="{ item }">
          <table-actions :btnEditAllowed="isBtnAllowed('EditButton')" :btnDeleteAllowed="isBtnAllowed('DeleteButton')"
            @edit="viewItem(item[idFieldName])" @delete="deleteItem(item[idFieldName], item.description)" />
        </template>
      </base-table>
    </template>

    <template #editing-dialog>
      <EditForm v-if="showDetailsDlg" :itemId="currentId" @close="showDetailsDlg = false" />
    </template>
  </base-data-table>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { RegionsDropdown, BaseTable, BaseDataTable, TableActions, SearchInput } from "@/pages/Components";
import BaseTableMixin from '@/mixins/BaseTableMixin';
import EditForm from './DistrictEditForm.vue';

export default {
  name: 'districts-list',
  mixins: [BaseTableMixin],

  components: {
    RegionsDropdown,
    BaseDataTable,
    BaseTable,
    TableActions,
    SearchInput,
    EditForm
  },

  data() {
    return {
      formName: 'Districts',
      idFieldName: 'district_id',
      propsToSearch: ['region_description', 'description', 'type_description', 'notes'],
      currentSort: 'region_description',

      historyMapping: {
        currentSort: 'region_description',
        currentSortOrder: 'asc',
        pagination: this.pagination,
        searchQuery: ''
      }
    }
  },

  methods: {
    ...mapActions({
      clearList: 'CLEAR_DISTRICT_LIST', // Called from the BaseTableMixin's mount()
      loadList: 'LOAD_DISTRICT_LIST',
      delete: 'DELETE_DISTRICT',
    }),
  },

  computed: {
    ...mapState({
      districtsList: (state) => state.RoadNetwork.district_list,
    }),

    tableData() {
      return this.customSort(this.districtsList, 'description')
    },

    headers() {
      return [
        { label: "road_network.region", value: "region_description", sortable: true },
        { label: "stdCols.name", value: "description", sortable: true },
        { label: "stdCols.type", value: "type_description", sortable: true },
        { label: "road_network.section_count", value: "sections", sortable: true },
        { label: "stdCols.notes", value: "notes", sortable: false },
      ]
    },
  },

  watch: {
    selectedRegion(newVal) {
      this.payload = { region_id: newVal }
      this.reloadData()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/mixins/BaseTable.scss';
</style>