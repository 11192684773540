<!--
File: DistrictEditForm.vue
Description: form for adding/editing a single district.
-->
<template>
  <modal v-if="eligible" :title="screenTitle" :form="formName" :errCount="errors.items.length"
    :hasUnsavedChanges="hasUnsavedChanges" @close="$emit('close')" @save="validate">

    <template slot='body'>
      <EditFields ref="dynamicForm" v-model="district" :fields="fields" :validations="validations" />
    </template>
  </modal>
</template>
<script>
import { mapActions } from 'vuex'
import { Modal, EditFields } from '@/pages/Components'
import { savedMessage } from '@/mixins/messagesMixin'
import permissions from "@/mixins/permissionsMixin"

export default {
  name: 'district-edit-form',
  mixins: [permissions],

  data() {
    return {
      formName: 'DistrictEditForm',
      eligible: false,

      district: {},
      initialFormState: null,
    }
  },

  props: {
    itemId: null
  },

  components: {
    Modal,
    EditFields,
  },

  async mounted() {
    // Check if we are eligible to view the form
    this.eligible = await this.checkIfScreenAllowed();
    if (!this.eligible) {
      this.$emit('close');
      return;
    };

    if (this.itemId) { // Load data on existing district
      this.district = await this.loadItem(this.itemId);
    };
    this.$nextTick(async () => {
      this.initialFormState = this.getCurrentState;
      await this.$refs.dynamicForm.validateForm();
    });
  },

  methods: {
    ...mapActions({
      loadItem: 'LOAD_DISTRICT_BY_ID',
      addItem: 'ADD_NEW_DISTRICT',
      editItem: 'UPDATE_DISTRICT',
      highlightRow: 'HIGHLIGHT_ROW'
    }),

    async validate() {
      const isValid = await this.$refs.dynamicForm.validateForm();
      if (!isValid) return;

      const theItem = this.getCurrentState;

      let errDesc = '';
      let newId;
      const action = !this.itemId ? this.addItem : this.editItem;
      const payload = !this.itemId ? theItem : { id: this.itemId, theItem };
      try {
        const res = await action(payload);
        newId = res?.district_id;
      } catch (err) {
        errDesc = err.message || this.$t('messages.unknown_error');
      };

      this.$nextTick(() => this.$validator.reset());
      this.$emit('close');
      await savedMessage(errDesc, this.$t('label.district'), this.district?.description_ru, this.district?.description_en);
      this.highlightRow(newId);
    },
  },

  computed: {
    screenTitle() {
      return this.itemId ? this.$t('screen_titles.district_upd') : this.$t('screen_titles.district_add');
    },

    fields() {
      return [
        { name: 'fk_region', type: 'custom', component: 'RegionsDropdown', class: 'md-layout-item md-size-60' },
        { 
          name: 'type', label: 'label.select_type', type: 'custom', component: 'RdbLookupDropdown', 
          class: 'md-layout-item md-size-40', props: { lookupType: 'district_type' } 
        },
        { name: 'description_en', label: 'stdCols.name_en', type: 'text', class: 'md-layout-item md-size-100' },
        { name: 'description_ru', label: 'stdCols.name_ru', type: 'text', class: 'md-layout-item md-size-100' },
        { name: 'description_kg', label: 'stdCols.name_kg', type: 'text', class: 'md-layout-item md-size-100' },
        { name: 'notes', label: 'stdCols.notes', type: 'text', class: 'md-layout-item md-size-100' },
      ];
    },

    validations() {
      return {
        fk_region: { required: true, numeric: true, min_value: 1 },
        type: { required: true, numeric: true, min_value: 1 },
        description_en: { required: true, min: 3 },
        description_ru: { required: true, min: 3 },
        description_kg: { required: true, min: 3 },
        //notes: { required: false },
      };
    },

    getCurrentState() {
      return {
        fk_region: this.district?.fk_region || 0,
        type: this.district?.type || 0,
        description_en: this.district?.description_en || '',
        description_ru: this.district?.description_ru || '',
        description_kg: this.district?.description_kg || '',
        notes: this.district?.notes || '',
      };
    },

    hasUnsavedChanges() {
      if (!this.initialFormState) return false;

      return this.district?.fk_region !== this.initialFormState.fk_region ||
        this.district?.type !== this.initialFormState.type ||
        this.district?.description_en !== this.initialFormState.description_en ||
        this.district?.description_ru !== this.initialFormState.description_ru ||
        this.district?.description_kg !== this.initialFormState.description_kg ||
        this.district?.notes !== this.initialFormState.notes;
    }
  }
}
</script>