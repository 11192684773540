import { render, staticRenderFns } from "./Districts.vue?vue&type=template&id=1786aab6&scoped=true"
import script from "./Districts.vue?vue&type=script&lang=js"
export * from "./Districts.vue?vue&type=script&lang=js"
import style0 from "./Districts.vue?vue&type=style&index=0&id=1786aab6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1786aab6",
  null
  
)

export default component.exports